import { IBKProductBase, IBKProductFamilyData, IBKProductFamilyDataWithContent } from '@bk/jscommondatas';

export class ProductFamily implements IBKProductFamilyDataWithContent<IBKProductBase, ProductFamily> {
	id: number;
	name: string;
	description: string;
	_name: { [lang: string]: string };
	_description: { [lang: string]: string };
	parentFamilyId: number;
	_picto: number;
	includeInLoadBalancing: boolean;

	$products: IBKProductBase[] = [];
	$productIds: number[] = [];
	$childFamilies: ProductFamily[] = [];
	$childFamilyIds: number[] = [];

	get products(): IBKProductBase[] {
		return this.$products;
	}

	get productIds(): number[] {
		return this.$productIds;
	}

	get childFamilies(): ProductFamily[] {
		return this.$childFamilies;
	}

	get childFamilyIds(): number[] {
		return this.$childFamilyIds;
	}

	constructor(original: IBKProductFamilyData) {
		this.id = original.id;
		this.name = original.name;
		this.description = original.description;
		this._description = { ...original._description };
		this._name = { ...original._name };
		this.parentFamilyId = original.parentFamilyId;
		this._picto = original?._picto || 0;
		this.includeInLoadBalancing = original?.includeInLoadBalancing || false;
	}

	addProductToFamily(product: IBKProductBase): void {
		this.$products.push(product);
		this.$productIds.push(product.id);
	}

	addChildFamily(family: ProductFamily): void {
		this.$childFamilies.push(family);
		this.$childFamilyIds.push(family.id);
	}
}

export class ProductFamilies {
	private $allFamilies: { [id: string]: ProductFamily };

	get allFamilies() {
		return this.$allFamilies;
	}

	constructor(fs: { [id: string]: IBKProductFamilyData }) {
		//  First init
		this.$allFamilies = {};
		//  First loop, build
		for (const id in fs) {
			const f: ProductFamily = new ProductFamily(fs[id]);
			//  Store
			this.$allFamilies[f.id] = f;
		}
		//  Second loop, feed
		for (const id in this.$allFamilies) {
			const f: ProductFamily = this.$allFamilies[id];
			if (f.parentFamilyId <= 0) continue;
			if (this.$allFamilies?.[f.parentFamilyId]) {
				this.$allFamilies[f.parentFamilyId].addChildFamily(f);
			}
		}
		//  Finished
		return;
	}

	/**
	 * Import function
	 */
	static fromImportedDatas(imported: { [id: string]: IBKProductFamilyData }): ProductFamilies {
		return new ProductFamilies(imported);
	}

	/**
	 * Add a product to a family
	 */
	addProductToFamily(product: IBKProductBase): void {
		const fId: number = product._familyId;
		if (fId <= 0 || !this.$allFamilies?.[fId]) {
			return;
		}
		//  Get the family and append the product
		this.$allFamilies[fId].addProductToFamily(product);
		//  Finished
		return;
	}

	/**
	 * Add a multiple products to a family
	 */
	addProductsToFamily(products: { [id: string]: IBKProductBase } = {}): void {
		Object.values(products).forEach((product) => {
			this.addProductToFamily(product);
		});
	}
}
