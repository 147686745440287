enum MerimLogLevelEnum {
	LOG = 'LOG',
	DEBUG = 'DEBUG',
	ERROR = 'ERROR',
}

export class MerimLogger {
	/**
	 * A log message with a log level of 'LOG'. Intended for tracking key events in the app's flow, UI events etc.
	 * @param message a message to be formatted into final log. if there is any dynamic data to be shown, include it as interpolated string
	 * @param uuid a uuid to include in the final log (for example, in kiosk, the uuid represents the active order id)
	 * @param userId an id of a signed in user
	 */
	static log(message: string, uuid?: string, userId?: string) {
		console.log(this.getLogMessage(message, MerimLogLevelEnum.LOG, uuid, userId));
	}

	/**
	 * A log message with a log level of 'DEBUG'. Intended for tracking deeper events, mainly calculations and evaluations.
	 * @param message a message to be formatted into final log. if there is any dynamic data to be shown, include it as interpolated string
	 * @param uuid a uuid to include in the final log (for example, in kiosk, the uuid represents the active order id)
	 * @param userId an id of a signed in user
	 */
	static debug(message: string, uuid?: string, userId?: string) {
		//eslint-disable-next-line no-restricted-syntax
		console.debug(this.getLogMessage(message, MerimLogLevelEnum.DEBUG, uuid, userId));
	}

	/**
	 * A log message with a log level of 'ERROR'. Intended for tracking error events in the application.
	 * @param message a message to be formatted into final log. if there is any dynamic data to be shown, include it as interpolated string
	 * @param uuid a uuid to include in the final log (for example, in kiosk, the uuid represents the active order id)
	 * @param userId an id of a signed in user
	 */
	static error(message: string, uuid?: string, userId?: string) {
		console.error(this.getLogMessage(message, MerimLogLevelEnum.ERROR, uuid, userId));
	}

	/**
	 * Processes message and data into final log format.
	 * @param message a message to be processed into final log format
	 * @param uuid a uuid to include in the final log (for example, in kiosk, the uuid represents the active order id)
	 * @param userId an id of a signed in user
	 * @param logLevel log level: 'LOG' | 'DEBUG' | 'ERROR'
	 * @returns final formatted log with all needed information
	 */
	private static getLogMessage(
		message: string,
		logLevel: MerimLogLevelEnum,
		uuid?: string,
		userId?: string
	): string {
		const order: string = uuid ? `[UUID]=${uuid}: ` : '';
		const user: string = userId ? `[USER ID]=${userId}: ` : '';

		return `[${logLevel}]: ${order}${user}${message}`;
	}
}
