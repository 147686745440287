/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Discount } from '../model/models';
import { DiscountBaseInfo } from '../model/models';
import { DiscountDraft } from '../model/models';
import { DiscountUpdateInput } from '../model/models';
import { HTTPValidationError } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable()
export class DiscountsApiClient {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create New Discount Draft From Parent
     * Create new discount draft from parent.
     * @param parentDiscountId 
     * @param discountUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost(parentDiscountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DiscountDraft>;
    public createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost(parentDiscountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DiscountDraft>>;
    public createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost(parentDiscountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DiscountDraft>>;
    public createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost(parentDiscountId: number, discountUpdateInput: DiscountUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parentDiscountId === null || parentDiscountId === undefined) {
            throw new Error('Required parameter parentDiscountId was null or undefined when calling createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost.');
        }
        if (discountUpdateInput === null || discountUpdateInput === undefined) {
            throw new Error('Required parameter discountUpdateInput was null or undefined when calling createNewDiscountDraftFromParentV1DiscountsParentDiscountIdPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DiscountDraft>(`${this.configuration.basePath}/v1/discounts/${encodeURIComponent(String(parentDiscountId))}`,
            discountUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Discount Draft
     * Create new empty discount draft.
     * @param discountUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewDiscountDraftV1DiscountsPost(discountUpdateInput: DiscountUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DiscountDraft>;
    public createNewDiscountDraftV1DiscountsPost(discountUpdateInput: DiscountUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DiscountDraft>>;
    public createNewDiscountDraftV1DiscountsPost(discountUpdateInput: DiscountUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DiscountDraft>>;
    public createNewDiscountDraftV1DiscountsPost(discountUpdateInput: DiscountUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (discountUpdateInput === null || discountUpdateInput === undefined) {
            throw new Error('Required parameter discountUpdateInput was null or undefined when calling createNewDiscountDraftV1DiscountsPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DiscountDraft>(`${this.configuration.basePath}/v1/discounts`,
            discountUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Discounts
     * Delete discounts. Only possible for not published discounts and drafts. If discount is a parent to draft, draft gets deleted too.
     * @param discountId Ids of discounts to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDiscountsV1DiscountsDelete(discountId: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteDiscountsV1DiscountsDelete(discountId: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteDiscountsV1DiscountsDelete(discountId: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteDiscountsV1DiscountsDelete(discountId: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (discountId === null || discountId === undefined) {
            throw new Error('Required parameter discountId was null or undefined when calling deleteDiscountsV1DiscountsDelete.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (discountId) {
            discountId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'discount_id');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/discounts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get discount or draft
     * Get a single discount or discount draft by id.
     * @param discountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDiscountV1DiscountsDiscountIdGet(discountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Discount | DiscountDraft>;
    public getDiscountV1DiscountsDiscountIdGet(discountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Discount | DiscountDraft>>;
    public getDiscountV1DiscountsDiscountIdGet(discountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Discount | DiscountDraft>>;
    public getDiscountV1DiscountsDiscountIdGet(discountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (discountId === null || discountId === undefined) {
            throw new Error('Required parameter discountId was null or undefined when calling getDiscountV1DiscountsDiscountIdGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Discount | DiscountDraft>(`${this.configuration.basePath}/v1/discounts/${encodeURIComponent(String(discountId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Discounts
     * Get a list of all available discounts.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDiscountsV1DiscountsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DiscountBaseInfo>>;
    public getDiscountsV1DiscountsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DiscountBaseInfo>>>;
    public getDiscountsV1DiscountsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DiscountBaseInfo>>>;
    public getDiscountsV1DiscountsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DiscountBaseInfo>>(`${this.configuration.basePath}/v1/discounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update discount or draft
     * Update discount draft.
     * @param discountId 
     * @param discountUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDiscountV1DiscountsDiscountIdPatch(discountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DiscountDraft | Discount>;
    public updateDiscountV1DiscountsDiscountIdPatch(discountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DiscountDraft | Discount>>;
    public updateDiscountV1DiscountsDiscountIdPatch(discountId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DiscountDraft | Discount>>;
    public updateDiscountV1DiscountsDiscountIdPatch(discountId: number, discountUpdateInput: DiscountUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (discountId === null || discountId === undefined) {
            throw new Error('Required parameter discountId was null or undefined when calling updateDiscountV1DiscountsDiscountIdPatch.');
        }
        if (discountUpdateInput === null || discountUpdateInput === undefined) {
            throw new Error('Required parameter discountUpdateInput was null or undefined when calling updateDiscountV1DiscountsDiscountIdPatch.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<DiscountDraft | Discount>(`${this.configuration.basePath}/v1/discounts/${encodeURIComponent(String(discountId))}`,
            discountUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate Discount Draft
     * Validate discount draft. If draft is based on parent, all the draft properties will be applied on top of parent and parent with original configuration will cease to exist. Draft will then be nullified and soft-deleted.
     * @param draftId 
     * @param discountUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateDiscountDraftV1DiscountsDraftIdValidatePost(draftId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Discount>;
    public validateDiscountDraftV1DiscountsDraftIdValidatePost(draftId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Discount>>;
    public validateDiscountDraftV1DiscountsDraftIdValidatePost(draftId: number, discountUpdateInput: DiscountUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Discount>>;
    public validateDiscountDraftV1DiscountsDraftIdValidatePost(draftId: number, discountUpdateInput: DiscountUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (draftId === null || draftId === undefined) {
            throw new Error('Required parameter draftId was null or undefined when calling validateDiscountDraftV1DiscountsDraftIdValidatePost.');
        }
        if (discountUpdateInput === null || discountUpdateInput === undefined) {
            throw new Error('Required parameter discountUpdateInput was null or undefined when calling validateDiscountDraftV1DiscountsDraftIdValidatePost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Discount>(`${this.configuration.basePath}/v1/discounts/${encodeURIComponent(String(draftId))}/validate`,
            discountUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
