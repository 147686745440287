import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { hasAccessPermission, hasReportingPermission } from '@libs/dash/core';
import { Error } from '@libs/dash/core/entity';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { getCurrentUser } from '../../store/auth/selectors';
import { CurrentUser } from '../../store/auth/state';
import { State } from '../../store/router/state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@libs/shared/modules/i18n';
import { getCurrentLanguageParam } from '../../store/router/selectors';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
	private store = inject(Store<State>);
	private router = inject(Router);
	private snackBar = inject(MatSnackBar);
	private translateService = inject(TranslateService);

	canActivate(): Observable<boolean> {
		return this.store.pipe(
			select(getCurrentUser),
			withLatestFrom(this.store.pipe(select(getCurrentLanguageParam)), this.translateService.selectTranslation('login')),
			map(([user, _, translatedTexts]) => {
				if (this.isAdmin(user)) {
					return true;
				} else if (!hasReportingPermission(user.permissions)) {
					this.router.navigate(['/account/authentication-failed', { error: Error.forbidden }]);
					return false;
				} else if (!hasAccessPermission(user.permissions)) {
					this.snackBar.open(translatedTexts.snackbars.error, '', { duration: 2000 });
					this.router.navigate(['/noaccess']);
					return false;
				}
				return true;
			})
		);
	}

	isAdmin(user: CurrentUser) {
		return user.is_admin;
	}
}
